<template>
    <a
        :href="Link"
        class="text-sm font-semibold leading-6 text-gray-900">
        {{ Titel }}
    </a>
</template>

<script setup>
const props = defineProps({
    component: {
        type: Object,
        required: true
    }
})

const Titel = computed(() => {
    return props.component.Titel ?? ''
})

const Link = computed(() => {
    return props.component.Link ?? ''
})
</script>
